<template>
    <div class="supervisedetail">
        <full-scroll></full-scroll>
        <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
            <div class="flex flex-pack-center flex-1">
                <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">
                    {{
                        this.$route.params.type == 1
                            ? "监督岗详情"
                            : this.$route.params.type == 2
                                ? "最新通知详情"
                                : this.$route.params.type == 3
                                    ? "连心桥详情"
                                    : this.$route.params.type == 4
                                        ? "故事会详情"
                                        : this.$route.params.type == 5
                                            ? "政策法律宣传详情"
                                            : this.$route.params.type == 7
                                                ? '接访群众预安排详情'
                                                : superviseDetail.tid == 12
                                                    ? "会议详情"
                                                    : superviseDetail.tid == 13
                                                        ? "履职活动详情"
                                                        : superviseDetail.tid == 14
                                                            ? "提出议案详情"
                                                            : superviseDetail.tid == 15
                                                                ? "履职培训详情"
                                                                : superviseDetail.tid == 16
                                                                    ? "为民办好事实事详情"
                                                                    : superviseDetail.tid == 17
                                                                        ? "代表述职详情"
                                                                        : this.$route.params.type == 99 ? "心愿详情" : ''
                    }}
                </div>
            </div>
            <div class="flex-end flex-1 flex" @click="backHome">
                <div class="my_text_center">
                    <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome m_b10" />
                    <p>返回首页</p>
                </div>
            </div>
        </div>
        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
            <div class="my_text_center supervisedetail-box">
                <p class="
                        font-size28 font-blod
                        color_FF
                        msg-mid-title-bot-title
                    ">
                    {{ superviseDetail.title }}
                </p>
                <p class="font-size22 color_9FB msg-mid-title-bot-line">
                    {{ superviseDetail.releaseTime }} 发布 |
                    {{
                        this.$route.params.type == 1
                            ? "监督岗"
                            : this.$route.params.type == 2
                                ? "最新通知"
                                : this.$route.params.type == 3
                                    ? "连心桥"
                                    : this.$route.params.type == 4
                                        ? "故事会"
                                        : this.$route.params.type == 5
                                            ? "政策法律宣传"
                                            : this.$route.params.type == 6
                                                ? "履职风采"
                                                : this.$route.params.type == 7
                                                    ? "接访群众预安排"
                                                    : ''
                    }}
                </p>

                <van-popup v-model="showDoc" style="width: 60vw; padding: 2vw" closeable>
                    <div v-if="isShowDoc">
                        <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=https://gyrd.itlic.com' +
                            superviseDetail.annex
                            " frameborder="0" width="100%" height="800"></iframe>
                    </div>
                    <div v-else>
                        <div>
                            <embed :src="superviseDetail.annex" width="100%" height="800" />
                        </div>
                    </div>
                </van-popup>

                <div class="supervisedetail-desc">
                    <div class="font-size28 color_FF flex flex-end m_r20 m_b10"
                        v-if="superviseDetail.annex != null && superviseDetail.annex != []">
                        <van-button size="small" plain type="primary" class="m_r20" @click="preview">附件预览</van-button>
                        <!-- <van-button
                            size="small"
                            plain
                            type="info"
                            @click="download(superviseDetail.annex)"
                            >附件下载
                        </van-button> -->
                    </div>

                    <div class="flex flex-justify-center" v-if="superviseDetail.video != null && superviseDetail.video != ''">
                        <video :src="superviseDetail.video" style="height: 49vh" controls></video>
                    </div>
                    <div class="color_FF" >
                        <div v-html="superviseDetail.content"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
            <div class="my_text_center" @click="goBack">
                <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF"></div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";
export default {
    components: { fullScroll },
    data() {
        return {
            todayMtime: "",
            todayTime: "",
            superviseDetail: null,
            showDoc: false,
            isShowDoc: true,
        };
    },
    created() {
        this.getTime();
        let data = JSON.parse(decodeURIComponent(this.$route.params.data));
        data.content = data.content.replace(
            /<img/g,
            '<img style="max-width:100%;height:auto;display:block;margin:10px auto;"'
        );
        data.annex = JSON.parse(data.annex)[0];
        if (data.annex != null) {
            if (data.annex.includes(".pdf")) {
                this.isShowDoc = false;
            }
        }
        this.superviseDetail = data;
    },
    mounted() {
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    methods: {
        //返回首页
        backHome() {
            this.$router.push("/");
        },

        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },
        goBack() {
            this.$router.back();
        },

        //预览附件
        preview() {
            this.showDoc = true;
        },

        //附件下载
        // download(data) {
        //     if (data.includes(".pdf")) {
        //         let url = "https://gyrd.itlic.com" + data;
        //         download(url);
        //     } else {
        //         let a = document.createElement("a");
        //         a.href = "https://gyrd.itlic.com" + data;
        //         a.click();
        //     }
        // },
    },
};
</script>
